.content-centered {
  @include media('>=medium') {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column wrap;
    height: 100%;
    padding-right: 8rem;
  }
}

body .cc-animate.cc-revoke.cc-bottom {
  transform: translateY(0);
  border-radius: 0;
}
