.group_action {
    display: block;
    position: relative;
    transition: all .3s ease-in-out;
    z-index: 1;

    &:hover {
        transition: all .6s ease-in-out;
        transform: scale(1.05);
    }
}