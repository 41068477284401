$ICON_SIZE: 4rem;
$ARROW_SIZE: 1rem;

body.bot-visible {
  overflow: hidden;
}

.bot-action-wrapper {
    position: relative;
    max-width: initial;
  
    @include media('>=medium') {
      display: flex;
      justify-content: center;
      align-items: center;
    }
}

.bot-window {
  @include anim($dur:.5s);

  position: fixed;
  left: 0;
  top: 0;


  width: 100vw !important;
  height: 100vh !important;

  visibility: hidden;
  opacity: 0;
  
  max-height: 100vh !important;
  max-width: 100vw !important;

  z-index: 99999;

  box-sizing: border-box;

  overflow-y: auto;
  padding: 0 15px;
  overflow-x: hidden;
  background: #000000 url(../img/bot-background.svg) no-repeat center center / 260px !important;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .bot-close {
    @include anim();

    cursor: pointer;
    width: 50px;
    height: 50px;

    position: fixed;
    right: 20px;
    top: 20px;
    opacity: 1;

    background: $red;

    z-index: 99999;
    
    line-height: 50px;
    padding: 15px;

    img {
        position: relative;
        top: 0;
    }

    &:hover {
        background: $blue;
    }
  
  }
}

.chat {
  margin: 5rem 0;
}

.chat__bubble {
  @include anim();
  font: normal 1rem/1.5 $fago_bold;
  display: flex;

  opacity: 1;

  &.ng-enter {
    transition: opacity .3s ease-in-out;
    opacity: 0;
  }
  
  &.ng-enter-active {
    opacity: 1;
  }

  &.ng-leave {
    opacity:0;
  }
  &.ng-leave.ng-leave-active {
    opacity:0;
  }

  + .chat__bubble {
    margin-top: 2rem;
  }

  @include media('>=desktop') {
    font-size: 1.5rem;
  }
}

.chat__bubble--user {
  justify-content: flex-end;
}

.chat__writing {
  span {
    animation: blink 1s infinite;

    &:nth-of-type(2) {
      animation-delay: .15s
    }

    &:nth-of-type(3) {
      animation-delay: .3s
    }
  }
}

.chat__user-icon {
  flex-basis: $ICON_SIZE;
  position: relative;
  width: $ICON_SIZE;
  height: $ICON_SIZE;
  background-color: $white;
  border-radius: 50%;
  z-index: 1;
  border: 4px solid $white;

  @include media('>=desktop') {
    $ICON_SIZE: 5.5rem;
    flex-basis: $ICON_SIZE;
    width: $ICON_SIZE;
    height: $ICON_SIZE;
  }

  .chat__user--stop {
    position: absolute;
    background: $red;
    width: 30px;
    height: 30px;
    z-index: 999;
    right: -11px;
    top: -5px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    display: none;

    &:before {
      width: 15px;
      height: 15px;
      left: 7.5px;
      top: 7.5px;
      position: absolute;
      content: '';
      display: block;
      background: rgba(#ffffff,.7);

      transition: all .3s ease-in-out;
      
      cursor: pointer;

    }
  }

  &.chat__user--talking {
    animation: talking 1.2s infinite ease-in-out alternate;

    .chat__user--stop {
      display: block;
    }

    &:hover {
      .chat__user--stop:before {
        transform: scale(1.05);
        background: #ffffff;
      }
    }
  }
}

.chat__user-icon--user {
  order: 2;
}

.chat__body {
  position: relative;
  background-color: $blue;
  width: calc(100% - #{$ICON_SIZE});
  max-width: 20rem;
  flex-basis: calc(100% - #{$ICON_SIZE});
  padding: 1rem;
  margin-left: 1rem;
  z-index: 0;
  border-radius: .5rem;

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: -$ARROW_SIZE;
    top: 1rem;
    width: 0;
    height: 0;
    z-index: -1;
    border-top: $ARROW_SIZE solid transparent;
    border-bottom: $ARROW_SIZE solid transparent;
    border-right: $ARROW_SIZE solid $blue;
  }

  @include media('>=desktop') {
    $ICON_SIZE: 5.5rem;
    $ARROW_SIZE: 1.5rem;

    padding: 1.5rem;
    width: calc(100% - #{$ICON_SIZE});
    flex-basis: calc(100% - #{$ICON_SIZE});
    max-width: 35rem;

    &:after {
      left: -$ARROW_SIZE;
      border-top: $ARROW_SIZE solid transparent;
      border-bottom: $ARROW_SIZE solid transparent;
      border-right: $ARROW_SIZE solid $blue;
    }
  }
}

.chat__body--cover {
  max-width: 100px;
  position: relative;

  .chat__body--cover-stop {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba($red,.7);
    width: 100%;
    height: 100%;
    z-index: 10;

    &:before {
      width: 20px;
      height: 20px;
      left: 40px;
      top: 40px;
      position: absolute;
      content: '';
      display: block;
      background: rgba(#ffffff,.7);

      transition: all .3s ease-in-out;
      
      cursor: pointer;

    }

    &:hover {
      &:before {
        transform: scale(1.05);
        background: #ffffff;
      }
    }
  }

  img {
    width: 100%;
  }
}

.chat__body--user {
  margin-left: initial;
  margin-right: 1rem;
  background-color: $white;
  color: $black;

  &:after {
    left: initial;
    right: -$ARROW_SIZE;
    border-right: none;
    border-left: $ARROW_SIZE solid $white;

    @include media('>=desktop') {
      $ARROW_SIZE: 1.5rem;

      right: -$ARROW_SIZE;
      border-left: $ARROW_SIZE solid $white;
    }
  }
}

.chat__select-headline {
  text-align: center;
  font: normal 1rem/1.5 $fago_bold;

  @include media('>=desktop') {
    font-size: 1.5rem;
  }
}

.chat__select-option {
  border: 1px solid $lightgrey;
  padding: 1rem;
  cursor: pointer;

  + .chat__select-option {
    margin-top: 1rem;
  }

  &:hover {
    background-color: $red;
    color: $white;
  }
}

.chat__hr {
  border-color: $lightgrey;
}
