.media-text {
  padding-bottom: 5rem;

  &:first-of-type {

    @include media('>=medium') {
      padding-bottom: 0;
    }
  }
}

.media-text__media {
  @include media('>=medium') {
    .media-text:nth-of-type(even) & {
      order: 2;
    }
  }
}

.media-text__text {
  padding: 2.5rem 1.5rem;

  @include media('>=medium') {
    padding: 4rem 1.5rem;
  }
}
