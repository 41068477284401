$CHRONIC_STAGES: 8;

.chronic {
  @extend .m-t-40;

  @include media('<=medium') {
    .owl-stage-outer {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }

  .owl-stage {
    position: relative;
    padding-bottom: 1rem;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: .5rem;
      background: $black;
     // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 8.041'%3E%3Cpath fill='%23030304' d='M1391.572 7.912H0V.13h1391.572L1400 4.021z'/%3E%3Cpath d='M73.406 0h20.102v8.041H73.406zM249.658 0h20.102v8.041h-20.102zM425.658 0h20.102v8.041h-20.102zM601.906 0h20.102v8.041h-20.102zM777.992 0h20.102v8.041h-20.102zM954.207 0h20.102v8.041h-20.102zM1130.24 0h20.102v8.041h-20.102zM1306.492 0h20.102v8.041h-20.102z' fill='%2377bce4'/%3E%3C/svg%3E");
    }
  }
}

.chronic--full {
  position: relative;
  display: none!important;

  @include media('>=wide') {
    display: flex!important;
    padding-bottom: 2rem;

    &:before {
      @include anim();
      content: '';
      display: block;
      position: absolute;
      width: 1.5rem;
      height: 2.5rem;
      bottom: 0;
      left: .75rem;
      background: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.143 41.891'%3E%3Cpath d='M0 14.007L14.136 0l14.007 14.007zM0 14.007h28.143v27.884H0z' fill='%23e41d2c'/%3E%3C/svg%3E%0A");
      z-index: 4;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: .75rem;
      width: calc(100% - 1.5rem);
      height: .5rem;
      background: $black;
      //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 8.041'%3E%3Cpath fill='%23030304' d='M1391.572 7.912H0V.13h1391.572L1400 4.021z'/%3E%3Cpath d='M73.406 0h20.102v8.041H73.406zM249.658 0h20.102v8.041h-20.102zM425.658 0h20.102v8.041h-20.102zM601.906 0h20.102v8.041h-20.102zM777.992 0h20.102v8.041h-20.102zM954.207 0h20.102v8.041h-20.102zM1130.24 0h20.102v8.041h-20.102zM1306.492 0h20.102v8.041h-20.102z' fill='%2377bce4'/%3E%3C/svg%3E");
    }
  }

  @for $i from 1 through $CHRONIC_STAGES {
    &[data-stage="#{$i}"].anim--started {
      &:before {
        left: calc(100% / #{$CHRONIC_STAGES} * #{$i} - calc((100% / #{$CHRONIC_STAGES}) / 2) - .75rem );
      }
    }
  }
}

.chronic__header {
  @include anim();
  background-color: $black;
  padding: 1rem;
  font: normal 1rem/1.5 $fago_bold;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  .center & {
    background-color: $red;
  }
}

.chronic__inner {
  padding: 2rem;

  @include media('>=wide') {
    padding: 1rem;
  }
}

@for $i from 1 through $CHRONIC_STAGES {
  .chronic[data-stage="#{$i}"].anim--started {

    .chronic__item:nth-child(#{$i}) {

      .chronic__header {
        background-color: $red;
      }

      .chronic__icon {
        opacity: 1;
      }
    }
  }
}

.chronic__icon {
  @include anim();
  max-width: 5rem;
  height: 7rem;
  opacity: .4;

  .center & {
    opacity: 1;
  }
}


@include media('<=medium') {
  .chronic__item.currentState:before {
    content: '';
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 2.5rem;
    bottom: -2rem;
    left: calc(50% - 0.75rem);
    background: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.143 41.891'%3E%3Cpath d='M0 14.007L14.136 0l14.007 14.007zM0 14.007h28.143v27.884H0z' fill='%23e41d2c'/%3E%3C/svg%3E%0A");
    z-index: 4;
  }
}

.chronic__indicator {
  width: 2.5rem;
  height: 2rem;
  position: absolute;
  left: 0;
  bottom: 0;
}

.chronic__item {
  position: relative;
  &:after {
    position: absolute;
    bottom: -20px;
    z-index: 3;
    left: calc(50% - 10px);
    width: 20px;
    height: 8px;
    background: $blue;
    content: '';
    display: block;

    @include media('<=medium') {
      bottom: -16px;
    }
  }
}
