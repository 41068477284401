@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes talking{
  0%{
    box-shadow: 0px 0px 2px 0px rgba($red,0.9);
  }
  15%{
    box-shadow: 0px 0px 2px 15px rgba($red,0.9);
  }
  25%{
    box-shadow: 0px 0px 2px 7px rgba($red,0.9);
  }
  50%{
    box-shadow: 0px 0px 2px 10px rgba($red,0.9);
  }
  65%{
    box-shadow: 0px 0px 2px 12px rgba($red,0.9);
  }
  75%{
    box-shadow: 0px 0px 2px 7px rgba($red,0.9);
  }
  100%{
    box-shadow: 0px 0px 2px 0px rgba($red,0.9);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px rgba($blue,.2), 0px 0px 0px 10px rgba(255,255,255,0);
  }
}

@keyframes borderPulseRed {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(0, 0, 0,.4), 0px 0px 0px 0px rgba(0,0,0,1);
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px rgba($red,.2), 0px 0px 0px 10px rgba(0,0,0,0);
  }
}


@keyframes pulseScale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.125);
  }
}