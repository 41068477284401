.legend {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font: normal .75rem/1.5 $fago_medium;
  display: flex;
  justify-content: center;

  @include media('<=medium') {
    display: block;
    position: relative;
    bottom: -3.75rem;
  }
}

.legend--top {
  top: 0;
  bottom: initial;
}

.legend__item {
  position: relative;
  padding: .5rem;
  background-color: $black;
  color: $white;

  + .legend__item {
    margin-top: 0;
  }

  @include media('>=desktop') {
    padding: 1rem 1.5rem;
  }

  @include media('<=medium') {
    display: block;    
  }
}

.legend__item--red-outline {
  border: 1px solid $red;
  background: transparent;
  color: $red;
}

.legend__item--red-filled {
  background-color: $red;
  color: $black;
}

.legend__line {
  display: inline-block;
  width: 2rem;
  height: .25rem;
  background-color: $red;
}
