.video {
  position: relative;
}

.video__poster,
.video__container {
  @include anim();
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50% / cover;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);
    z-index: 2;
  }

  .video--started & {
    opacity: 0;
    visibility: hidden;
  }
}

.video__trigger {
  @include anim();
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width:  7.5rem;
  height: 7.5rem;
  padding: 2rem;
  background: rgba($black, .8);
  border: none;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    transform: translateX(10%);
  }

  .video--started & {
    opacity: 0;
    visibility: hidden;
  }
}

.video__container {
  &:before {
    display: none;
  }

  .video--started & {
    opacity: 1;
    visibility: visible;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}
