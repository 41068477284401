$INTRO_PROFILE_SIZE: 30rem;

.intro {
  position: relative;
}

.intro__content {
  position: relative;
}

.intro__image {
  position: relative;
}

.intro__image--profile {
  background: no-repeat 50% 40% / 150%;
  margin-top: 2rem;

  @include media('>=desktop') {
    padding-top: initial;
    height: $INTRO_PROFILE_SIZE;
    width: $INTRO_PROFILE_SIZE;
  }
}

.intro__image--switch-button {
  @include anim();

  position: absolute; 
  left: 10px; 
  top: 10px; 
  z-index: 999;

  width: 30px;
  height: 30px;

  border-radius: 50%;
  background: $blue;
  color: #fff;

  text-align: center;

  font-size: 33px;
  line-height: 28px;
  font-weight: bold;
  
  animation: pulseScale 2s cubic-bezier(.5,1,.9,1) infinite;

  &:hover {
    cursor: pointer;

    background: $red;
  }

  &:before {
    width: 15px;
    height: 2px;
    background: $white;
    display: block;
    position: absolute;
    top: calc(50% - 1px);
    left: 7.5px;
    content: '';
  }

  &:after {
    height: 15px;
    width: 2px;
    background: $white;
    display: block;
    position: absolute;
    left: calc(50% - 1px);
    top: 7.5px;
    content: '';

    transition: all .3s ease-in-out;
  }

  &.in {
    &:after {
      transform: rotate(90deg);
    }
	}

  img {
    display: inline-block;
    width: 15px;
    height: auto;
    position: relative;
    top: 3px;
  }
}

.intro__image--profile-startpage {
  margin-top: 0;

  @include media('>=desktop') {
    height: 17rem;
  }

  @include media('>=wide') {
    height: 20rem;
  }

  @include media('>=full') {
    height: 30rem;
  }
}

.intro__image--background {
  display: none;

  @include media('>=desktop') {
    display: block;
    position: absolute;
    background-color: $black;
    width: calc(100% - #{$INTRO_PROFILE_SIZE});
    height: 100%;
    top: 0;
    left: $INTRO_PROFILE_SIZE;
    z-index: -1;
  }
}

.intro__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro__brand-logo {
  width: 6rem;
  height: 3.5rem;

  @include media('>=desktop') {
    width: 8rem;
    height: 5rem;
  }
}

.intro__header-sep {
  width: .125rem;
  height: 3.5rem;
  margin: 0 1rem;
  background-color: $lightgrey;

  @include media('>=desktop') {
    height: 5rem;
  }
}

.intro__person-name {
  font-size: 2rem;

  @include media('>=desktop') {
    font-size: 3rem;
  }
}

.intro__quote-container {
  padding: 2rem 1.5rem;
  color: $white;
  background-color: $black;

  @include media('>=desktop') {
    padding: 2.5rem 2rem 4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $INTRO_PROFILE_SIZE;
  }
}

.intro__quote {
  position: relative;
  font-size: 2rem;
  font-family: $fago_boldita;
  quotes: '„' '“';

  @include media('>=wide') {
    font-size: 3rem;
  }

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
}

.intro__cta {
  @include media('>=desktop') {
    position: absolute;
    top: 65%;
    transform: (-50%);
    left: calc(#{$INTRO_PROFILE_SIZE} + 30%);
  }

  @include media('>=wide') {
    left: calc(#{$INTRO_PROFILE_SIZE} + 45%);
  }

  @include media('<=desktop') {
    padding: 1.5rem;
    background: $black;
  }
}
