@font-face {
  font-family: 'FagoWebBold';
  src: url("../fonts/fago/FagoWeb-Bold.woff") format("woff");
  text-transform: none;
}

@font-face {
  font-family: 'FagoWebBoldIta';
  src: url("../fonts/fago/FagoWeb-BoldIta.woff") format("woff");
  text-transform: none;
}

@font-face {
  font-family: 'FagoWebMedium';
  src: url("../fonts/fago/FagoWeb-Medium.woff") format("woff");
  text-transform: none;
}

@font-face {
  font-family: 'FagoWeb';
  src: url("../fonts/fago/FagoWeb.woff") format("woff");
  text-transform: none;
}

@font-face {
  font-family: 'MiloSerifScWeb';
  src: url("../fonts/milo/MiloSerifScWeb.woff") format("woff");
  font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  text-transform: none;
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  -moz-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  -moz-font-feature-settings: "kern=1, liga=1, pnum=1, lnum=1";
  -ms-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  -o-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
}
@font-face {
  font-family: 'MiloSerifWeb';
  src: url("../fonts/milo/MiloSerifWeb.woff") format("woff");
  font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  text-transform: none;
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  -moz-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  -moz-font-feature-settings: "kern=1, liga=1, pnum=1, lnum=1";
  -ms-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
  -o-font-feature-settings: "kern" 1, "liga" 1, "pnum" 1, "lnum" 1;
}

h1, h2, h3,
.h1, .h2, .h3 {
  font-family: $fago_bold;
  margin: 0;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

h1, .h1 {
  font-size: 3rem;
  line-height: 1.25;

  + p {
    @extend .m-t-40;
  }

  @include media('>=medium') {
    font-size: 4.5rem;
  }
}

h2, .h2 {
  font-size: 2rem;
  line-height: 1.25;

  + p {
    @extend .m-t-24;
  }

  @include media('>=medium') {
    font-size: 3rem;
  }
}

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.25;

  + p {
    @extend .m-t-24;
  }

  @include media('>=medium') {
    font-size: 2rem;
  }
}
