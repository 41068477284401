$BORDER_RADIUS: 2rem;

.sound-character__item {
  @include anim();
  background-color: $black;
  color: $white;
  border-radius: $BORDER_RADIUS;
  display: flex;
  font-family: $fago_bold;
  font-size: 1rem;
  opacity: 0;

  .anim--started & {
    opacity: 1;
  }

  @for $i from 1 through 25 {
    .anim--started .column:nth-child(#{$i}) & {
      transition-delay: calc(#{$i} * .2s);
    }
  }
}

.sound-character__item-title {
  padding: .5rem;
  text-align: center;
  flex: 1;
}

.sound-character__definition {
  padding: .5rem .25rem;
  background-color: $red;
  border-radius: $BORDER_RADIUS;
  margin-left: auto;

  &:after {
    content: '%';
  }
}
