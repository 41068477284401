.section {
  position: relative;
  padding: 3.75rem 1.5rem;

  @include media('>=medium') {
    padding: 7.5rem 1.5rem;
  }
}

.section--small {
  padding: 2rem 1.5rem;

  @include media('>=medium') {
    padding: 3rem 1.5rem;
  }
}

.section--no-y-pad-top {
  padding-top: 0;
}

@include media('<=medium') {
  .section--no-y-pad-top-mobile {
    padding-top: 0;
  }
}

.section--no-y-pad-bottom {
  padding-bottom: 0;
}

.section--no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.section--no-y-pad-mob {
  padding-bottom: 0;
  @include media('>=medium') {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.section--intro-profile {
  padding: 2rem 1.5rem;

  @include media('>=medium') {
    padding: 4rem 1.5rem;
  }
}

.section--white {
  background-color: $white;
}

.section--red {
  background-color: $red;
}

.section--black {
  background-color: $black;
}

.section--lightergrey {
  background-color: $lightergrey;
}

.section--lightgrey {
  background-color: $lightgrey;
}

.section--cream {
  background-color: $cream;
}

.section--blue {
  background-color: $blue;
}

.section--60vh {
  min-height: 60vh;
}

.section--w-90 {
  width: 90vw;
  margin: 0 auto;
}

@include media('<=desktop') {

  .section--no-x-pad-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .section--w-100-mobile {
    width: 100vw;
    margin: 0 auto;
  }
}

.section--center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section--chat {
  background: $black url(../img/bot-background.svg) no-repeat center center / 360px;
}
