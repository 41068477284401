.section-nav--wrapper {
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    margin: 0;
    padding: 0;

    z-index: 9999;

    li {
        &:before {
            display: none;
        }

        a {
            @include anim();

            opacity: .7;
            border: 2px solid #fff;
            width: 14px;
            height: 14px;
            margin: 15px 0;
            display: block;
            background: $lightgrey;
            border-radius: 50%;

            &.active {
                background: $red;
            }
        }
    }
}