@use "sass:math";

.card {
  @include anim($prop: transform);
  background-color: $white;

  &.card--profile {
    .anim{
      line-height: 0;
    }
  }
}

.card--expert {
  display: flex;
  flex-flow: column wrap;
}

.card--has-accent {
  background-color: $white;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    padding-top: 100%;
    top: -1rem;
    right: -1rem;
    background: transparent no-repeat 0 0 / cover;
    z-index: -2;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    background-color: $white;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.card--has-accent-blue {
  &:before {
    background-image: url(../img/icons/card-accent_blue.svg);
  }
}

.card--has-accent-red {
  &:before {
    background-image: url(../img/icons/card-accent_red.svg);
  }
}

.card--has-accent-black {
  &:before {
    background-image: url(../img/icons/card-accent_black.svg);
  }
}

.card__header {
  background-color: $blue;
  color: $white;
  padding: 1rem;
  text-align: center;
  font: normal 1.5rem/1.5 $fago_bold;
}

.card__body {
  padding: 2rem;
  hyphens: auto;

  @include media('>=desktop') {
    padding: 1rem;
  }

  @include media('>=wide') {
    padding: 1.5rem;
  }
}

.card__title {
  font: normal 1.5rem/1.5 $fago_bold;

  @include media('>=desktop') {
    font-size: 1.125rem;
  }

  @include media('>=wide') {
    font-size: 1.5rem;
  }
}

.card__title--profile {
  font-size: 2rem;

  @include media('>=medium') {
    font-size: 2.5rem;
  }
}

.card__subtitle {
  display: block;
  font: normal 1.125rem/1.5 $milo_serif;

  svg {
    display: block;
    width: 5rem;
    height: 5rem;
  }
}

.card__text {
  @include media('>=desktop') {
    font-size: .75rem;
  }

  @include media('>=wide') {
    font-size: 1.125rem;
  }
}

.card__icon {
  height: 6rem;
  width: 100%;
  max-width: 100%;
  margin: .5rem auto 2.5rem;

  @include media('>=desktop') {
    height: 4rem;
  }

  @include media('>=wide') {
    height: 6rem;
  }
}

.card__image {
  background: no-repeat 50% 40% / 150% ;
  width: 100%;
  padding-bottom: math.percentage(math.div(1, 1));
}

.card__image--photo {
  position: relative;
  background: no-repeat 100%;
  background-size: cover;

  @include media('>=medium') {
    .card-expert-container .column:nth-child(2) & {
      order: 2;
    }
  }
}

.card__label {
  @include anim();
  background-color: $black;
  color: $white;
  padding: 2rem;

  @include media('>=desktop') {
    padding: 1.25rem 3rem;
  }

  .card--profile:hover & {
    background-color: $white;
    color: $black;
  }
}

.card__label--white {
  background-color: $white;
  color: $black;
}

.card__label--blue,
.card__content--blue {
  background-color: $blue;
}

.card__label--red,
.card__content--red {
  background-color: $red;
}

.card__content--black {
  background-color: $black;
  color: $white;
}

.card__content {
  position: relative;
  display: block;
  padding-top: math.percentage(math.div(9, 16));

  .card--info-animated & {
    padding-top: initial;

    @include media('>=wide') {
      padding-top: math.percentage(math.div(9, 16));
    }
  }
}

.card__inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;

  .card--info-animated & {
    position: relative;

    @include media('>=wide') {
      position: absolute;
    }
  }

  img {
    max-height: 100%;
  }
}

.card__inner--has-text {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.card__info-text {
  font: normal 2.5rem/1.25 $fago_bold;
}

.card__interest {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;

  + .card__interest {
    margin-left: 1rem;
  }
}

.card__interest-title {
  margin-top: 1rem;
  font: normal .75rem/1.5 $fago_regular;
}

.card__graphic {
  width: 5rem;
  height: 5rem;
}

.card__content--expert {
  padding: 1.5rem;
  padding-right: calc(1.5rem + 2rem);
  background-color: $black;
  color: $white;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    right: 0;
    width: 2rem;
    height: 2rem;
    background: no-repeat 0 0 / cover;
    background-image: url('/dist/img/icons/card-expert-element.svg');

    @include media('>=wide') {
      width: 4rem;
      background-image: url('/dist/img/icons/card-expert-element-big.svg');
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    background-color: $white;

    .card--meta & {
      background-color: $red;
    }

    .card--eye-square & {
      background-color: $blue;
    }
  }

  @include media('>=medium') {
    padding: 1rem 1.5rem;

    .card-expert-container .column:nth-child(2) & {
      order: 1;

      &:after {
        bottom: 0;
        top: auto;
      }
    }
  }

  @include media('>=medium') {
    .card-expert-container .column:nth-child(2) & {

      &:before {
        bottom: -2rem;
        top: auto;
      }
    }
  }

  @include media('>=desktop') {
    padding-right: calc(1.5rem + 3rem);

    &:after,
    &:before {
      width: 3rem;
      height: 3rem;
    }

    &:before {
      top: -3rem;
    }

    .card-expert-container .column:nth-child(2) & {
      &:before {
        bottom: -3rem;
        top: auto;
      }
    }
  }



  @include media('>=wide') {
    padding-right: calc(1.5rem + 4rem);
    &:after,
    &:before {
      width: 4rem;
      height: 4rem;
    }

    &:before {
      top: -4rem;
      bottom: auto;
    }

    .card-expert-container .column:nth-child(2) & {

      &:before {
        top: auto;
        bottom: -3.2em;
      }
    }
  }
}

.card__title--expert,
.card__subtitle--expert {
  font: normal 1.5rem/1.5 $fago_bold;


  @include media('>=desktop') {
    font-size: 2.5rem;
  }
}

.card__subtitle--expert {
  img {
    margin-top: .5rem;
    max-width: 7rem;

    &[src*="eyesquare"] {
      max-width: 6rem;
    }

    @include media('>=medium') {
      max-width: 10rem;

      &[src*="eyesquare"] {
        max-width: 8rem;
      }
    }
  }
}

.card__role {
  p + & {
    margin-top: .5rem;

    @include media('>=medium') {
      margin-top: 1rem;
    }
  }
}

.card-expert-container {
  padding-bottom: 0;
  position: relative;

  &.columns.is-gapless:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.columns.is-gapless:last-child {
    margin-bottom: 4rem;

    @include media('>=medium') {
      margin-bottom: 0;
    }
  }
}
