/**
 * 1. Set up a decent box model on the root element.
 */
html {
  box-sizing: border-box; /* 1 */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * 1. Make all elements from the DOM inherit from the parent box-sizing
 *    Since `*` has a specificity of 0, it does not override the `html` value
 *    making all elements inheriting from the root box-sizing value.
 *    See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, ::before, ::after {
  box-sizing: inherit; /* 1 */
}

/**
 * 1. Prevent outline on clickable elements
 */

*:focus { outline: none; }

/**
 * 1. Prevent horizontal scrolling.
 */
body {
  color: $black;
  font: 1.25rem / 1.5 $milo_serif;
  overflow-x: hidden; /* 1 */
}

p {
  margin: 0;

  + p,
  header + & {
    @extend .m-t-24;
  }

  + h2,
  + h3,
  + .h3 {
    @extend .m-t-32;
  }
}

.text-lead {
  font-family: $fago_bold;
  font-size: 1.5rem;
}

.text-lead--serif {
  font-family: $milo_serif;
}

.text-lead--xl {
  font-size: 2rem;
}

.text-lead--xxl {
  font-size: 2rem;

  @include media('>=wide') {
    font-size: 3rem;
  }
}

.text-high {
  position: relative;
  font-size: 80%;
  top: -.25rem;
}
