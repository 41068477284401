.header {
  position: relative;
  z-index: 99999;
}
.nav {
  @extend .navbar;
  background-color: transparent;
}

.nav--footer {
  min-height: initial;
  flex-wrap: wrap;

  + .nav--footer {
    margin-top: 2rem;
  }
}

.nav__brand {
  @extend .navbar-brand;
  font-family: $fago_bold;
  padding: 1rem 1rem 1.5rem;
  display: flex;
  align-items: center;

  @include media('>=desktop') {
    display: block;
  }
}

.nav__logo {
  svg {
    width: 4.75rem;
    height: 3.75rem;

    @include media('>=medium') {
      width: 8rem;
      height: 6.5rem;
    }

    @include media('>=wide') {
      width: 8rem;
      height: 6.5rem;
    }

    @include media('>=full') {
      width: 8rem;
      height: 6.5rem;
    }
  }
}

.nav__right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav__burger {
  @include anim();
  font: inherit;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;

  &:hover {
    opacity: .7;
    background-color: transparent;
  }

  @include media('>=desktop') {
    display: none;
  }
}

.nav__burger-box {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 24px;
}

.nav__burger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
  position: absolute;
  width: 32px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: $black;

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 32px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: $black;
  }

  &:after {
    bottom: -8px;
    transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
  }

  &:before {
    top: -8px;
    transition: top 75ms ease .12s,opacity 75ms ease;
  }

  .nav__burger--is-active & {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: rotate(45deg);

    &:after {
      bottom: 0;
      transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
      transform: rotate(-90deg);
    }

    &:before {
      top: 0;
      transition: top 75ms ease,opacity 75ms ease .12s;
      opacity: 0;
    }
  }
}

.nav__burger-title {
  line-height: 1;
  margin-right: .5rem;
  color: $black;
}

.nav__mobile-cta {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;

  @include media('>=desktop') {
    display: none;
  }
}

.nav__cta {
  display: none;
  font-size: .75rem;
  padding: .5rem;

  @include media('>=desktop') {
    display: block;
    align-self: center;
  }

  @include media('>=wide') {
    font-size: 1rem;
    padding: .5rem 1rem;
  }
}

.nav__mobile-cta-title {
  margin-right: .5rem;
  line-height: 1;
}

ul.menu {
  padding: 0;
  margin: 0;

  li {
    margin: 0;
    padding: 0;
    margin-left: 1.5rem;
    display: flex;

    @include media('<=medium') {
      margin-left: 0;
      display: block;
    }

    &::before {
      display: none;
    }

    &.has-dropdown {

      @include media('<=medium') {
        > a:first-child {
          width: calc(100% - 40px);
          display: inline-block;
          box-sizing: border-box;
        }
      }
      
      .dropdown--toggle {
        display: inline-block;
        width: 30px;
        box-sizing: border-box;
        text-align: center;

        > img {
          width: 14px;

          @include media('<=medium') {
            width: 20px;
          }

          display: inline-block;
        }
      }
    }

    ul {
      display: none;
      width: 100%;

      @include media('>=medium') {
        margin-top: 25px;
        padding-top: 20px;
        left: -10px;
        padding-left: 0;
        min-width: 280px;
      }

      @include media('<=medium') {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    &:hover {
      > a {
        color: $blue;
      }
    }

    @include media('>=medium') {
      &:hover > ul {
        display: block;
        position: absolute;

        > li {
          background: $white;
          padding: 5px 10px;
          position: relative;
          margin-left: 0;
        }
      }
    }

    @include media('<=medium') {
      ul.in {
        display: block;
        position: relative;  

        > li {
          background: $white;
          padding: 5px 10px;
          position: relative;
          margin-left: 0;
        }
      }
    }
  }
}


.menu {
  @extend .navbar-menu;
  padding-top: 0;
  font-family: $fago_bold;

  &:not(.menu--footer) {
    @include anim($prop: opacity);
    will-change: background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    visibility: hidden;
    opacity: 0;
    height: 0;
    background-color: #fff;
    position: absolute;
    top: 127px;
    left: 0;
  }

  @include media('>=desktop') {
    &:not(.menu--footer) {
      position: relative;
      margin-right: 0 !important;
      top: initial;
      left: initial;
      opacity: 1;
      height: initial;
      visibility: visible;
      justify-content: center;
      align-self: center;
      background: transparent;
      flex-direction: row;
    }
  }

  &.is-active {
    height: calc(85vh - 127px); /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 85 - 127px);
    width: 100%;
    visibility: visible;
    opacity: 1;
    background-color: #fff;
    display: flex;
    @include media('>=desktop') {
      height: initial;
    }
  }
}

.menu--footer {
  flex-direction: column;
  box-shadow: none;
  background-color: transparent;

  @include media('<=medium') {
    + .menu--footer {
      margin-top: 3rem;
    }
  }

  @include media('>=medium', '<=desktop') {
    flex: 1 0 20%;
  }

  @include media('>=desktop') {
    order: 2;
  }
}

.menu--footer-logo {
  @include media('>=medium', '<=desktop') {
    margin-top: 4rem;
    flex: 0 1 100%;
  }

  @include media('>=desktop') {
    order: 1;
  }
}

.menu__item:not(.menu__item--footer) {
  @include anim();
  border: 0;
  transition-property: transform, opacity;
  text-align: center;
  opacity: 0;
  transform: translateX(-1em);
  display: flex;
  flex: 1 auto;
  flex-shrink: 0;
  justify-content: center;

  span {
      align-self: center;
    }

  .is-active & {
    opacity: 1;
    transform: translateX(0);

    @for $i from 2 through 4 {
      &:nth-child(#{$i}) {
        transition-delay: calc(#{$i} * .1s);
      }
    }
  }

  &.active {
    color: $red;
  }

  @include media('>=desktop') {
    border: 0;
    text-align: left;
    opacity: 1;
    transform: initial;
    flex: initial;
    font-size: 1rem;

    + .menu__item {
      margin-left: 1.5rem;
    }
  }

  @include media('>=wide') {
    font-size: 1.25rem;
  }
}

.menu__item--cta {
  color: $red;
  font-family: $fago_bold;
}

.menu__item--footer {
  font-family: $fago_regular;
  display: block;
  color: $white;
}

.menu__item--inline {
  display: inline-block;

  + .menu__item--inline {
    margin-left: 1rem;
  }
}

.menu__title {
  color: $white;
  margin-bottom: 1rem;

  .menu__item {
    font-family: $fago_bold;
  }
}

.menu__text {
  font-family: $fago_regular;
  margin-top: 2rem;
}

$socialIcons: (
              fb: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='003-facebook' viewBox='0 0 20.014 20.014'%3E%3Cg data-name='Gruppe 9'%3E%3Cpath fill='%23fff' d='M10.007 0a10.007 10.007 0 1 0 10.007 10.007A10.018 10.018 0 0 0 10.007 0zM12.5 10.359h-1.632v5.8H8.455v-5.8H7.308V8.308h1.147V6.982a2.263 2.263 0 0 1 2.435-2.435l1.788.007v1.991h-1.3a.491.491 0 0 0-.512.559v1.205h1.838z' data-name='Pfad 46'/%3E%3C/g%3E%3C/svg%3E"),
              tw: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='002-twitter' viewBox='0 0 20.014 20.014'%3E%3Cg data-name='Gruppe 8'%3E%3Cpath fill='%23fff' d='M10.007 0a10.007 10.007 0 1 0 10.007 10.007A10.018 10.018 0 0 0 10.007 0zm4.464 7.717c0 .1.007.2.007.3a6.552 6.552 0 0 1-10.084 5.517A4.651 4.651 0 0 0 7.8 12.58a2.306 2.306 0 0 1-2.152-1.6 2.312 2.312 0 0 0 1.04-.039 2.3 2.3 0 0 1-1.843-2.258v-.029a2.294 2.294 0 0 0 1.043.288 2.307 2.307 0 0 1-.713-3.075 6.54 6.54 0 0 0 4.747 2.407 2.3 2.3 0 0 1 3.925-2.1 4.6 4.6 0 0 0 1.463-.559 2.314 2.314 0 0 1-1.01 1.273 4.579 4.579 0 0 0 1.323-.362 4.633 4.633 0 0 1-1.152 1.191z' data-name='Pfad 45'/%3E%3C/g%3E%3C/svg%3E"),
              ig: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='_001-instagram' data-name='001-instagram' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23fff%7D %3C/style%3E%3C/defs%3E%3Cg id='Gruppe_7' data-name='Gruppe 7'%3E%3Cg id='Gruppe_6' data-name='Gruppe 6'%3E%3Cpath id='Pfad_41' d='M21.885 23.913a2.025 2.025 0 1 0-2.028-2.029 2.032 2.032 0 0 0 2.028 2.029z' class='cls-1' data-name='Pfad 41' transform='translate(-11.885 -11.884)'/%3E%3Cpath id='Pfad_42' d='M32.845 15.913V13.96h-.255l-1.7.005.01 1.955z' class='cls-1' data-name='Pfad 42' transform='translate(-18.466 -8.345)'/%3E%3Cpath id='Pfad_43' d='M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0zm5.687 8.833v4.656a2.2 2.2 0 0 1-2.2 2.2H6.511a2.2 2.2 0 0 1-2.2-2.2V6.511a2.2 2.2 0 0 1 2.2-2.2h6.978a2.2 2.2 0 0 1 2.2 2.2z' class='cls-1' data-name='Pfad 43'/%3E%3Cpath id='Pfad_44' d='M21.249 23.111a3.152 3.152 0 1 1-6.072-1.182h-1.72v4.718a1.1 1.1 0 0 0 1.1 1.1h7.07a1.1 1.1 0 0 0 1.1-1.1v-4.718h-1.712a3.109 3.109 0 0 1 .234 1.182z' class='cls-1' data-name='Pfad 44' transform='translate(-8.097 -13.143)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"),
              xi: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='005-xing-icomoon' viewBox='0 0 20.012 20.012'%3E%3Cpath fill='%23fff' d='M10.006 0a10.006 10.006 0 1 0 10.006 10.006A10.006 10.006 0 0 0 10.006 0zM6.752 12.515H4.943a.267.267 0 0 1-.236-.123.279.279 0 0 1 0-.277l1.921-3.382a.009.009 0 0 0 0-.01L5.406 6.612a.246.246 0 0 1 .236-.389H7.45a.559.559 0 0 1 .5.338s1.24 2.15 1.25 2.162l-1.955 3.444a.553.553 0 0 1-.493.348zM15.3 4.146l-4 7.062a.011.011 0 0 0 0 .012l2.55 4.646a.281.281 0 0 1 0 .279.268.268 0 0 1-.24.115h-1.804a.565.565 0 0 1-.505-.344l-2.57-4.7L12.755 4.1a.53.53 0 0 1 .484-.344h1.827a.268.268 0 0 1 .24.115.282.282 0 0 1-.006.275z' data-name='Pfad 50'/%3E%3C/svg%3E"),
              li: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='_004-linkedin' data-name='004-linkedin' viewBox='0 0 20.014 20.014'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23fff%7D %3C/style%3E%3C/defs%3E%3Cg id='Gruppe_11' data-name='Gruppe 11'%3E%3Cg id='Gruppe_10' data-name='Gruppe 10'%3E%3Cpath id='Pfad_47' d='M24.809 21.3a1.6 1.6 0 0 0-1.453.8v-.687h-1.613c.021.455 0 4.854 0 4.854h1.613v-2.713a1.087 1.087 0 0 1 .053-.394.883.883 0 0 1 .828-.591c.584 0 .817.445.817 1.1v2.6h1.614v-2.787c-.001-1.49-.797-2.182-1.859-2.182zm-1.454.818h-.01a.121.121 0 0 0 .01-.017z' class='cls-1' data-name='Pfad 47' transform='translate(-12.979 -12.713)'/%3E%3Cpath id='Rechteck_133' d='M0 0h1.613v4.854H0z' class='cls-1' data-name='Rechteck 133' transform='translate(6.257 8.699)'/%3E%3Cpath id='Pfad_48' d='M10.007 0a10.007 10.007 0 1 0 10.007 10.007A10.018 10.018 0 0 0 10.007 0zm5.307 14.533a.779.779 0 0 1-.79.77h-9.1a.779.779 0 0 1-.789-.77V5.325a.78.78 0 0 1 .789-.77h9.1a.78.78 0 0 1 .79.77v9.208z' class='cls-1' data-name='Pfad 48'/%3E%3Cpath id='Pfad_49' d='M16.2 15.777a.841.841 0 1 0-.021 1.677h.01a.841.841 0 1 0 .011-1.677z' class='cls-1' data-name='Pfad 49' transform='translate(-9.125 -9.418)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
);

.menu__item--icon {
  position: relative;
  padding-left: 2.25rem;

  + .menu__item--icon {
    margin-top: .75rem;
  }

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: no-repeat 50% 50% / cover;
    left: 0;
  }
}

@each $social, $icon in $socialIcons {
  .menu__item--icon-#{$social} {
    &:before {
      background-image: $icon;
    }
  }
}
