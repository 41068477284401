.lang-switch {
  @include anim($prop: opacity);
  display: flex;
  flex-direction: row;
  padding: .5rem 1rem;
  opacity: 0;

  @include media('<=medium') {
    width: 100%;
  }

  .menu.is-active & {
    opacity: 1;
    transition-delay: .75s;
  }

  @include media('>=desktop') {
    opacity: 1;
    align-self: center;
    padding: 0;
    margin-left: 3rem;

    > span {
      margin: 0 .5rem;
    }
  }

  @include media('>=wide') {
    margin-left: 4rem;
  }
}

.lang-switch__item {
  font-family: $fago_bold;
  background-color: transparent;
  padding: 0;
  width: initial;
  color: $black;
  font-size: 1.125rem;
  border: 0;
  cursor: pointer;

  &:not(.active) {
    font-family: $fago_regular;
    color: $grey;
  }

  &:hover {
    background-color: transparent;
    color: $blue;
  }

  &:nth-of-type(1) {
    text-align: right;
  }

  &:nth-of-type(2) {
    text-align: left;
  }

  @include media('>=desktop') {
    line-height: 1.4;
  }
}
