.chart {
	margin-top: 5rem;
}

.chart__canvas-wrap {
	position: relative;
}

.chart__canvas-legend {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;

	display: flex;
	justify-content: space-around;
}

.chart__canvas-legend_item {
	flex: 1;
}

.chart__canvas-legend_letter {
	font: normal 1rem/1 $fago_bold;
	background-color: $red;
	color: $white;
	display: inline-block;
	width: 1.5rem;
	height: 1.5rem;
	padding: .25rem;
}

.chart__canvas {
	height: 5rem;
	
	@include media('>desktop') {
		height: 15rem;
	}
}

.chart__header {
	padding: 1rem .5rem;
	color: $white;
	background-color: $blue;
	text-transform: uppercase;
	font-family: $fago_medium;
	position: relative;
	overflow: visible;
	z-index: 2;

	.owl-item:nth-child(even) &,
	.chart__item:nth-child(even) & {
		background-color: $black;
	}
}

.chart__info-trigger {
	display: inline-block;
	margin: 0 0 0 .5rem;
	padding: 0;
	line-height: 1;
	top: .125rem;
	width: 1rem;
	height: 1rem;
	background-color: transparent;
	border: transparent;
	position: relative;
	cursor: pointer;
	
	animation: pulseScale 2s cubic-bezier(.5,1,.9,1) infinite;

	.chart__info-trigger-icon {
		border-radius: 50%;
		top: -14px;
		border: 2px solid $white;
		width: 22px;
		height: 22px;
		position: relative;
		color: $blue;
		display: inline-block;
		font-size: 1px;

		&:before {
			width: 10px;
			height: 2px;
			background: $white;
			display: block;
			position: absolute;
			top: calc(50% - 1px);
			left: 4px;
			content: '';
		}

		&:after {
			height: 10px;
			width: 2px;
			background: $white;
			display: block;
			position: absolute;
			left: calc(50% - 1px);
			top: 4px;
			content: '';

			transition: all .3s ease-in-out;
		}
	}

	&.in {
		background: transparent;

		.chart__info-trigger-icon {

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.chart__title {
	font-size: 1rem;
}

.chart__info-text {
	@include anim();
	background-color: $blue;
	position: absolute;
	width: 100%;
	font-size: 1rem;
	text-align: left;
	text-transform: none;
	padding: 2rem 1rem;
	left: 0;
	top: calc(100% + 2rem);
	opacity: 0;
	transform: translateY(-1rem);

	&:after {
		$ARROW_SIZE: 1.5rem;
		content: '';
		display: block;
		position: absolute;
		top: -$ARROW_SIZE;
		right: 1rem;
		width: 0;
		height: 0;
		border-left: calc(#{$ARROW_SIZE} * 1.25) solid transparent;
		border-right: calc(#{$ARROW_SIZE} * 1.25) solid transparent;
		border-bottom: $ARROW_SIZE solid $blue;
	}

	.chart__title--active + & {
		opacity: 1;
		transform: translateY(0);
	}
}
