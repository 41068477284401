@use "sass:math";

.slider--intro {

  @include media('>=desktop') {
    height: initial;

    .owl-item {
      @include anim();
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .owl-stage {
      display: flex;
    }
  }
}

.slider--structure {
  margin-top: 3rem;
  margin-bottom: 3rem;

  @include media('>=desktop') {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.slider__item {
  position: relative;
}

.slider__item--start {
}

.slider__item--flex {
  @include media('<desktop') {
    top: initial;
    transform: initial;
  }
  @include media('>=medium') {
    display: flex;
    height: 100%;
  }
}

.slider__item--bg {
  background: black;
  padding-bottom: math.percentage(math.div(9, 16));
  color: $white;

  @include media('<desktop') {
    background-image: none!important;
  }

  @include media('>=medium') {
    background: transparent no-repeat 0 0 / 100%;
    text-align: left;
  }
}

.slider__headline {
  @extend .h2;
  text-transform: uppercase;
  margin: 0;
}

.slider__image {
  max-width: 50rem;

  @include media('>=desktop') {
    max-width: 30rem;
  }

  @include media('>=wide') {
    max-width: 40rem;
  }

  @include media('>=full') {
    max-width: 50rem
  }
}

.slider__image--illu {
  max-width: initial;
  width: 100%;

  .owl-carousel .owl-item & {
    display: none;

    @include media('>=medium') {
      display: block;
    }
  }
}

.slider__text {
  margin-top: 2rem;
}

.slider__image--illu-mob {

  .owl-carousel .owl-item & {
    display: block;
  }
}

.slider__content {

  @include media('>=desktop') {
    position: absolute;
    top: 50%;
    left: 40rem;
    max-width: 20rem;
    transform: translate3d(-50%, -50%, 0);
  }

  @include media('>=wide') {
    left: 50rem;
  }

  @include media('>=full') {
    left: 60rem;
  }
}

.slider__content--bot {
  position: relative;
  max-width: initial;

  @include media('>=medium') {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media('>=desktop') {
    left: 50%;
  }
}

.slider__item-content {

  @include media('<desktop') {
    position: relative;
    top: initial;
    transform: initial;
  }

  @include media('>=desktop') {
    max-width: 20rem;
  }

  @include media('>=full') {
    margin-left: 2rem;
  }
}

.slider__content--intro {
  width: 100%;
  text-align: center;

  @include media('>=medium') {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: none;
  }
}

.owl-carousel {
  touch-action: manipulation
}

.owl-carousel .owl-stage-outer {
  @include media('>=desktop') {
    overflow: visible!important;
  }
}

.owl-dots {
  @extend .m-t-24;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-dots .owl-dot {
  width: initial;
  display: inline-block;
  zoom: 1;
}

.owl-dots .owl-dot.active span {
  background-color: $red;
}

.owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: $lightgrey;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px;
}
