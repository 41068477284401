.collapse {}

.collapse__trigger {
  cursor: pointer;
  color: $black;
  border: none;
  padding: 0;
  display: inline;
  font: normal 1.125rem/1.5 $fago_regular;

  &:hover {
    background-color: transparent;
    color: $black;
  }

  .collapse--open & {
    display: none;
  }
}

.collapse__trigger--less {

  .collapse--open & {
    opacity: 1;
    visibility: visible;
    display: block;
  }
}

.collapse__target {
  @include anim();
  @extend .m-t-24;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-2rem);

  .collapse--open & {
    transform: translateY(0);
    max-height: 300vh;
    opacity: 1;
  }
}
