@use "sass:math";

.boxes {
  padding-top: 2rem;

  @include media('>=medium') {
    padding-top: 3.5rem;
  }

  @include media('>=desktop') {
    padding-top: 5rem;
  }
}

.box {
  display: flex;
  padding: 1rem;
  font-family: $fago_bold;

  @include media('>=medium') {
    padding: 3rem 2rem;
  }
}

/**
 * 1. Inner padding + width of arrow + space between both
 */
.box--link {
  @include anim();
  background-color: $white;
  position: relative;
  padding-left: 4.5rem; /* 1 */

  @include media('>=medium') {
    padding-left: 6.5rem;
    font-size: 1.5rem;
  }

  &:before,
  &:after {
    @include anim();
    position: absolute;
    $width: 2rem;
    $height: 1rem;
    content: '';
    display: block;
    background: no-repeat 0 0 / #{$width} #{$height} url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 49.493 22.092'%3E%3Cpath d='M35.714 19.321l6.362-6.362H0v-3.9h42l-6.284-6.284L38.447 0l11.046 11.046-11.046 11.046z'/%3E%3C/svg%3E%0A");
    width: $width;
    height: $height;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    @include media('>=medium') {
      $width: 3rem;
      $height: 1.5rem;
      left: 2rem;
      background-size: #{$width} #{$height};
      width: $width;
      height: $height;
      margin-right: $height;
    }
  }

  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 49.493 22.092'%3E%3Cpath d='M35.714 19.321l6.362-6.362H0v-3.9h42l-6.284-6.284L38.447 0l11.046 11.046-11.046 11.046z' fill='%23fff'/%3E%3C/svg%3E%0A");
    z-index: 1;
  }

  &:hover {
    background-color: $black;
    color: $white;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

.box--square {
  @include anim($prop: transform);
  flex-direction: column;
  background-color: $black;
  padding-top: 100%;
  position: relative;

  &.box--active {
    background-color: $white;
    color: $black;
    padding-top: 0;

    @include media('>=medium') {
      padding-top: 100%;
    }

    @include media('>=wide') {
      padding-top: math.percentage(math.div(4, 6));
    }
  }

  @include media('>=wide') {
    padding-top: math.percentage(math.div(4, 6));
  }

  @include media('>=desktop') {
    &:hover {
      transform: scale(1.05);
    }
  }
}

.box__content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  .box--active & {
    position: relative;
    padding: 1.5rem 0.5rem;

    @include media('>=medium') {
      position: absolute;
      padding: 1.5rem;
    }
  }

  @include media('>=wide') {
    padding: 2rem;
  }
}

.box__title,
.box__text {
  @include anim();
}

.box__title {
  font-size: 1.5rem;
  color: $white;

  .box--active & {
    color: $black;
  }
}

.box__text {
  margin-top: 1rem;
  width: 100%;
  transform: translateY(1rem);
  opacity: 0;
  font-size: 1rem;

  .box--active & {
    opacity: 1;
    transform: translateY(0);
  }

  @include media('>=wide') {
    font-size: 1.125rem;
  }
}

.box__trigger {
  @include anim();
  position: absolute;
  bottom: 2rem;
  color: $white;
  cursor: pointer;
  margin-top: auto;
  font: normal 1rem/1.5 $fago_medium;

  .box--active & {
    color: $black;
    opacity: 0;
  }
}

.box--flip {
  cursor: pointer;
  font-family: $fago_regular;
}

.box__illu {
  @include anim();
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 100%;
  max-width: 20rem;
  padding-top: 100%;
  background: no-repeat 50% / contain;

  .box--active & {
    opacity: 0;
  }

  @include media('>=wide') {
    max-width: 17rem;
  }
}

.box__illu--bigger {
  background-size: 150%;
}
