.scroll--top {
    @include anim();

    cursor: pointer;
    width: 50px;
    height: 50px;

    position: fixed;
    right: 20px;
    bottom: 40px;
    opacity: 1;

    background: $red;

    z-index: 9999;
    
    line-height: 50px;
    padding: 15px;

    img {
        position: relative;
        top: 2px;
    }



    &:hover {
        background: $blue;
    }
}