a {
  @include anim($prop: color);
  text-decoration: none;
  color: $black;
}

a:hover {
  color: $blue;
}

a:hover,
a:active {
  color: $blue;
}

a.link-hover-red {
  &:hover,
  &:active {
    color: $red;
  }
}

.link--meta {
  font-family: $fago_medium;
  color: $red;
}
