$BAR_POS_BOTTOM: 2.5rem;

@for $i from 1 through 100 {
  .infographic__bar[data-amount="#{$i}"] {
    .anim--started & {
      max-height: calc(1% * #{$i});
    }
  }
}

.infographic {
  position: relative;
  font: normal 1rem/1.5 $fago_bold;
  color: $white;
  height: 100%;
  display: flex;

  .infographic__bar_value {
    position: relative;
    top: calc(50% - 1rem);
    font: normal 1.5rem/1.5 $fago_bold;

  }
}

.infographic--bubbles {
  flex-flow: column wrap;
  height: initial;

  @include media('>=desktop') {
    flex-flow: initial;
    flex-wrap: wrap;
  }

  @include media('>=wide') {
    .card__inner & {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.infographic__item {
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.infographic__item--bubble {
  height: initial;
  overflow: initial;
  width: 100%;

  + .infographic__item--bubble {
    margin-top: 1rem;
  }

  @include media('>=desktop') {
    flex: initial;
  }
}

.infographic__item--small {
  @include media('>=medium') {
    transform: scale(.8);
  }

  @include media('>=desktop') {
    flex: 1;
    width: initial;
  }

  @include media('>=desktop', '<wide') {
    font-size: 70%;
  }
}

.infographic__bar,
.infographic__bar-title {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.infographic__bar {
  @include anim($prop: max-height);
  width: 90%;
  background: $white;
  bottom: $BAR_POS_BOTTOM;
  left: 5%;
  height: 100%;
  max-height: 0;
}

.infographic__bubble {
  position: relative;
  background-color: $blue;
  border-radius: 3rem;
  display: flex;
  min-height: 6rem;

  &:before {
    content: '';

    left: -.75rem;
    top: 50%;
    transform: translateY(-50%);

    position: absolute;
    width: 0;
    height: 0;
    border-top:  1rem solid transparent;
    border-bottom:  1rem solid transparent;
    border-right: 1rem solid $blue;
  }

  @include media('>=desktop') {
    width: 100%;

    .infographic__item--small & {
      &:before {

        top: initial;
        bottom: -1.25rem;
        left: 2.5rem;
        border: none;

        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;

        border-top: 1rem solid $blue;
      }
    }
  }
}

.infographic__bubble--red {
  background-color: $red;

  &:before {
    border-right-color: $red;
  }
}

.infographic__bubble--black {
  background-color: $black;

  &:before {
    border-right-color: $black;

    @include media('>=desktop') {
      .infographic__item--small & {
        border-top-color: $black;
      }
    }
  }
}

.infographic__bubble-text {
  flex: 1;
  padding: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.infographic__bubble-amount {
  margin-left: auto;
  width: 6rem;
  padding: .5rem 0;
  border-radius: 50%;
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;

  .infographic__bubble--black & {
    background-color: $white;
    color: $black;
  }
}

.infographic__title {
  text-align: center;
}

.infographic__bar--red {
  background-color: $red;
}

.infographic__bar--black {
  background-color: $black;
}
