button,
.button {
  @include anim();
  display: block;
  padding: .5rem 1rem;
  background-color: $red;
  color: $white;
  font-family: $fago_medium;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  cursor: pointer;

  p + & {
    @extend .m-t-24;
  }

  &:hover {
    background-color: $blue;
    color: $white;
  }

  @include media('>=desktop') {
    width: initial;
  }

  @include media('<=medium') {
    font-size: 1.25rem;
  }

  &:disabled {
    opacity: .7;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.button--bot {
  display: inline-block;
  cursor: pointer;
}

.button--cta {
  min-width: 10rem;
  padding: .5rem 1.5rem;
}

.button--slider-cta {
  @include media('>=medium') {
    width: initial;
  }

  @include media('>=desktop') {
    position: absolute;
    bottom: -1.5rem;
    right: 5rem;
  }
}

.button--round {
  border-radius: 100%;
  padding: .5rem;
  line-height: 0;
}

.button--blue {
  background-color: $blue;

  &:hover {
    background-color: $red;
  }

  &.button--blue--hover--dark {

    &:hover {
      background-color: darken($blue,10);
    }
  }
}

.button--has-icon {
  svg {
    fill: #fff;
    width: 1.25rem;
    height: 1.25rem;
  }
}

.button--transparent {
  background-color: transparent;
  color: $black;

  &:hover {
    background-color: transparent;
    color: $black;
  }
}

.button--scroll-down {
  display: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;

  span {
    display: block;
  }

  svg {
    width: 2rem;
    height: 3.75rem;
  }

  @include media('>=desktop') {
    display: block;
  }
}

.button--scroll-down-abs {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.button--submit {
  width: 100%;
  margin-top: 1.5rem;
  border: none;
  border-radius: 0;
  padding: 1rem;
}

.button--pulse {
  animation: borderPulse 2s cubic-bezier(.5,1,.9,1) infinite;

  &:hover {
    opacity: 1;
    animation-play-state: paused;
  }
}

.button--pulse--red {
  animation: borderPulseRed 2s cubic-bezier(.5,1,.9,1) infinite;

  &:hover {
    opacity: 1;
    animation-play-state: paused;
  }
}

.button--auto {
  width: auto;
  display: inline-block;
}

.button--tooltip-toggle {
  @include anim();

  width: 30px;
  height: 30px;

  border-radius: 50%;
  background: $blue;
  color: #fff;

  text-align: center;

  font-size: 33px;
  line-height: 33px;
  font-weight: bold;
  
  display: inline-block;

  animation: pulseScale 2s cubic-bezier(.5,1,.9,1) infinite;

  &:hover {
    cursor: pointer;

    background: $red;
  }
}