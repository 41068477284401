.form {
  margin: 1rem 0;
  font: normal 1rem/1.5 $fago_regular;
}

.form__group {
  @include anim();
  position: relative;
  background-color: $white;
  padding: 1.75rem 1rem 1.5rem;
  border: 1px solid transparent;

  + .form__group {
    margin-top: 1rem;
  }

  &.has-error {
    border-color: #fff !important;
    box-shadow: inset 0 0 0 5px $red;
  }

  .error--message {
    color: $red;
    font-size: 0.8rem;
    position: absolute;
    font-style: italic;
  }
}

.form__group--checkbox {
  background: transparent;
  padding: initial;
}

.form__group--focused {
  border-color: $grey;
}

.form__input {
  width: 100%;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.form__input--checkbox {
  width: initial;
}

.form__label {
  @include anim();
  color: $lightgrey;
  position: absolute;
  transform-origin: 0 50%;

  .form__group--focused & {
    transform: translateY(-1.25rem) scale(.8);
  }
}

.form__label--checkbox {
  color: $black;
  position: relative;
}

.form__select {
  appearance: none;
  border:none;
  border-radius: 0;
  font-size: 1em;
  width: 100%;
  background: no-repeat right center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.43 9.5L5.93 11 12 17.07 18.07 11l-1.5-1.5L12 14.07 7.43 9.5z'/%3E%3C/svg%3E");

}
