ul {
  padding: 0 0 0 2rem;
  list-style: none;

  + h2,
  + .h3 {
    @extend .m-t-32;
  }
}

li {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -1.25rem;
    top: .375rem;
    width: .5rem;
    height: 1rem;
    background: no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 13'%3E%3Cpath d='M5.34 6.5L0 11.7 1.34 13 8 6.5 1.34 0 0 1.3z'/%3E%3C/svg%3E") 0 0 / .5rem;
  }

  + li {
    margin-top: .5rem;
  }
}

ul.list-red {
  li {
    &:before {
      background: no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#e41d2c' viewBox='0 0 8 13'%3E%3Cpath d='M5.34 6.5L0 11.7 1.34 13 8 6.5 1.34 0 0 1.3z'/%3E%3C/svg%3E") 0 0 / .5rem;
    }
  }
}
